'use client'
import { useState, useEffect } from 'react';
import Image from 'next/image'
import FrontSearchBar from '@components/FrontSearchBar'


const Hero = ({ dictionary, slogan, searchParams, lang, districts }) => {

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    // Set the initial width
    setScreenWidth(window.innerWidth);

    // Update the width on window resize
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='w-full h-[400px] z-0 relative'>
      <div className="h-full">
          <Image
            src={screenWidth < 500 ? '/images/mobilebanner.webp' : '/images/banner.webp'}
            fill={"true"}
            priority={"true"}
            className='h-[400px]'
            style={{
                objectFit: 'cover'
            }}
            alt="Banner Image"
        />
      </div>
      <FrontSearchBar lang={lang} slogan={slogan} dictionary={dictionary} />
</div>
  )
}

export default Hero