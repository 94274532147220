'use client'
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SmallSwiperImage from '@components/SmallSwiperImage'
import 'swiper/css';
import Link from "next/link";


const TransportCardSlider = ({ title, description, btsStations, url, lang }) => {

    return (
        <section className='w-full pl-3 mt-5'>
            {/* <h2 className='text-maincolor font-bold text-xl'>{title}</h2> */}
            {url ? (
                <Link href={`/${url}`}>
                    <h2 className='text-maincolor font-bold text-xl'>{title[lang]}</h2>
                </Link>
            ) : (
                <h2 className='text-maincolor font-bold text-xl'>{title[lang]}</h2>
            )}
            <p className="text-secondary">{description}</p>
            <Swiper
                spaceBetween={0}
                // slidesPerView={2.5}
                // grid={{
                //     rows: 2,
                //     fill: "row",
                // }}
                slidesPerView={"auto"}
                className="mt-2 block sm:hidden"
            >
                {btsStations && btsStations.slice(0, Math.ceil(btsStations.length / 2)).map((bts, index) => (
                    <SwiperSlide className="district-swiper-slide" key={index}>
                        <SmallSwiperImage
                            title={`${(btsStations[index * 2]?.type).toUpperCase()} ${btsStations[index * 2]?.stationName}`}
                            slug={btsStations[index * 2]?.slug}
                            route='stations'
                            bgColor={btsStations[index * 2]?.colorCode}
                            imageSrc={btsStations[index * 2]?.coverImage}
                            index={index * 2}
                        />
                        {index * 2 + 1 < btsStations.length ? (
                            <SmallSwiperImage
                                title={`${(btsStations[index * 2 + 1]?.type).toUpperCase()} ${btsStations[index * 2 + 1]?.stationName}`}
                                slug={btsStations[index * 2 + 1]?.slug}
                                route='stations'
                                bgColor={btsStations[index * 2 + 1]?.colorCode}
                                imageSrc={btsStations[index * 2 + 1]?.coverImage}
                                index={index * 2 + 1}
                            />
                        )
                            : null
                        }
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

export default TransportCardSlider