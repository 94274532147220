import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import LinkComponent from './LinkComponent'

const SmallSwiperImage = ({ title, slug, imageSrc, route, bgColor = 'bg-maincolortrans' }) => {
    const imageLink = imageSrc || `/images/thonglor.png`

    return (
        <LinkComponent href={`/${route}/${slug}`} className='relative district-swiper-small-image w-[150px] h-[150px]' >
            <div className='w-[140px] h-[140px] relative overflow-hidden rounded-md'>
                <Image
                    src={imageLink}
                    fill='true'
                    className='object-cover'
                    alt={`Listings in ${title}`}
                />
                <div className='absolute bottom-0 left-0 right-0 rounded-b-md flex justify-center h-[50px] items-start bg-maincolortrans overflow-hidden' style={{ borderTop: `${bgColor} 1px solid` }}>
                    <h3 className={`text-light z-10 px-1 rounded-sm text-md text-center`}>{title}</h3>
                </div>
            </div>
        </LinkComponent >
    )
}

export default SmallSwiperImage